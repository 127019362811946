(window as any).global = window;


import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';

import 'core-js/es/array';

  // Run `npm install --save classlist.js`.

  // Run `npm install --save web-animations-js`.

  import 'core-js/es/reflect';

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

(window as any).__Zone_enable_cross_context_check = true;


import 'zone.js';  // Included with Angular CL__Zone_enable_cross_context_check I.Angular 12 requires import from 'zone.js'.
